<template>
  <div class="d-flex flex-column justify-content-center align-items-start" :class="{ 'w-100': isLoading }">
    <hs-loading v-show="isLoading" />
    <div v-show="!isLoading">
      <h5 class="validation-title" slot="title">
        {{ $t(`sign_up.${product}.form_validation.title`) }}
      </h5>
      <p class="validation-subtitle mt-2 mb-4">
        {{ $t(`sign_up.${product}.form_validation.subtitle`) }}
      </p>
      <PhoneCodeAuth
        :phone="phone"
        :userId="userId"
        :is-sign-up="true"
        :ddi="ddi"
        :invalid-code="invalidCode"
        @updatePhone="update"
        @changePhoneNumber="changePhoneNumber"
        @removeWarning="removeWarning"
        @shouldSkip="update"
        @loadFinished="loadFinished"
        @resendedCode="resendTracking"
      />
    </div>
  </div>
</template>

<script>
import debug from 'debug';
import { mapState, mapActions, mapMutations, mapGetters } from 'vuex';
import { hsLoading } from '@/components';
import ToastHelper from '@/shared/helpers/toast';
import PhoneCodeAuth from '@/components/PhoneCodeAuth';
import { analyticsService, hubspotService, tapfiliateService } from '@/services';
import Tapfiliate from '@/shared/mixins/Tapfiliate';

const logging = debug('hs:sign-up-step-validation');

export default {
  mixins: [Tapfiliate],
  props: {
    product: {
      type: String,
      required: true,
    },
    inviteCode: {
      type: String,
      default: '',
    },
  },
  components: {
    hsLoading,
    PhoneCodeAuth,
  },
  data() {
    return {
      isLoading: true,
      resendDisabled: true,
      ddi: 'BR',
      phone: '',
      userId: '',
      invalidCode: false,
    };
  },
  computed: {
    ...mapState('school', ['newSchool', 'selectedSchool']),
  },
  methods: {
    ...mapMutations('school', ['setNewSchool']),
    ...mapActions('auth', ['signUp']),
    async update(data) {
      await this.registerSchool(data);
    },
    resendTracking(data) {
      if (data.isPhoneChanging) return;
      const { name, phone, email } = this.newSchool;
      analyticsService.track({
        event: 'Sign Up Resend Code',
        props: {
          name: name,
          phone: phone,
          email: email,
        },
      });
    },
    loadFinished() {
      this.isLoading = false;
    },
    changePhoneNumber(data) {
      this.phone = data.phone.replace(/[\s\-/\-/{()}]+/g, '');
      this.ddi = data.ddi;

      this.setNewSchool({ ...this.newSchool, phone: this.phone, ddi: data.ddi });
    },
    identify(name, email, phone, school, schoolData, invited_affiliate) {
      const { p } = this.$route.query;
      let props = {
        name: name,
        email: email,
        phone: phone,
        product: school.product,
        school_id: school.id,
        invited_affiliate,
        signup_page: p === 'd14' ? p : undefined,
      };

      try {
        analyticsService.track({
          event: 'Signed Up',
          props,
        });

        analyticsService.group({
          groupId: school.id,
          traits: {
            name: name,
            plan: 'free',
          },
        });
      } catch (error) {
        logging('analyticsService: ' + error);
      }

      hubspotService.sendSignUp({
        ...schoolData,
        utm: this.$route.query,
      });
    },

    async registerSchool(data) {
      try {
        const { checkUseTerms, email, name, password, phone, product, ddi } = this.newSchool;
        const inviteCode = this.inviteCode;
        const tapfiliate_must_send_referral = this.tapFiliateCookieNotPresent();
        const tapfiliate_referrer_code = this.tapFiliateReferrerCode();
        const document_type = this.newSchool.document_type.toLowerCase();
        const schoolData = {
          checkUseTerms,
          email,
          name,
          password,
          phone,
          product,
          inviteCode,
          tapfiliate_must_send_referral,
          tapfiliate_referrer_code,
          sms_token: data.smsToken,
          sms_token_id: data.smsTokenId,
          ddi,
          [document_type]: this.newSchool.document_number,
        };
        const { school } = await this.signUp({ ...schoolData });
        this.identify(name, email, phone, this.selectedSchool, schoolData, inviteCode);
        if (!this.tapFiliateCookieNotPresent()) {
          tapfiliateService.customer(school.id);
        }

        this.$emit('proceed');
        ToastHelper.successMessage(this.$t(`sign_up.${this.product}.form_name.toast.success`));
      } catch (error) {
        if (error.response?.status == 401) {
          const { name, phone, email, ddd } = this.newSchool;
          ToastHelper.dangerMessage(this.$t(`sign_up.${this.product}.form.toast.unauthorized`));
          analyticsService.track({
            event: 'Sign Up Code Error',
            props: {
              name: name,
              phone: ddd + phone,
              email: email,
            },
          });
          this.invalidCode = true;
        } else {
          ToastHelper.dangerMessage(this.$t(`sign_up.${this.product}.form.toast.error`));
        }
      }
    },
    removeWarning() {
      this.invalidCode = false;
    },
  },
  created() {
    this.phone = this.newSchool.phone;
    this.ddi = this.newSchool.ddi;
    this.userId = this.newSchool.email;
  },
};
</script>

<style lang="scss" scoped>
.validation-title {
  font-weight: 700;
  font-size: 32px;
  line-height: 115%;
  letter-spacing: -1px;
  margin-bottom: 1rem;
}
.validation-subtitle {
  font-weight: 300;
  font-size: 16px;
  line-height: 150%;
}
</style>
