<template>
  <div :class="displayMobileBanner ? 'mx-2 pt-4' : ''">
    <img v-if="displayMobileBanner" :src="mobileBanner" alt="banner" class="mb-4" />
    <structure-auth
      :illustration="illustration"
      :authStyles="authStyles"
      :shape="shape"
      :wrapperClass="(step === 2 && 'col-lg-6 px-4 px-lg-6 mx-lg-6') || ''"
      :slotClass="(step === 2 && 'justify-content-start') || ''"
    >
      <b-tabs v-model="step" nav-class="d-none" nav-wrapper-class="d-none">
        <b-tab v-for="tab in tabs" :key="tab.id" lazy>
          <component :is="tab.component" @proceed="step++" :product="product" :invite-code="inviteCode" />
        </b-tab>
      </b-tabs>
    </structure-auth>
  </div>
</template>

<script>
import StructureAuth from '@/auth/components/Auth';
import StepInfo from './components/StepInfo';
import StepName from './components/StepName';
import StepValidation from './components/StepValidation';
import StepQuestionaryNew from './components/StepQuestionaryNew';
import BrowserHelper from '@/shared/helpers/browser';

export default {
  components: { StructureAuth },
  data() {
    const { p, inviteCode } = this.$route.query;
    return {
      step: 0,
      product: !p || p === 'coproduction' ? 'sparkmembers' : p === 'd14' ? 'blog' : p,
      inviteCode,
      mobileBanner: require('@/assets/images/sign_up/signup-blog-mobile-banner.png'),
    };
  },
  created() {
    this.startQuestionary();
  },
  beforeDestroy() {
    const iframe = document.querySelector('iframe#launcher');
    if (iframe) iframe.style.visibility = 'hidden';
  },
  computed: {
    displayMobileBanner() {
      return this.product === 'blog' && BrowserHelper.isMobile();
    },
    authStyles() {
      return this.product === 'blog'
        ? {
            backgroundPosition: '80% 75%, bottom right',
            backgroundRepeat: 'no-repeat',
            backgroundSize: '35% 80%, 55% auto',
            minHeight: '100vh',
          }
        : {};
    },
    illustration() {
      if (this.product === 'blog') {
        return require('@/assets/images/sign_up/signup-blog-image.png');
      }
      return this.product === 'sparkfunnels'
        ? require('@/assets/images/funnel/funnels_illustration.svg')
        : require('@/assets/images/sign_up/illustration-signup-video.svg');
    },
    shape() {
      return require('@/assets/images/login/login_shape_vector.svg');
    },
    tabs() {
      return [
        {
          component: StepInfo,
          id: 0,
        },
        {
          component: StepName,
          id: 1,
        },
        {
          component: StepValidation,
          id: 2,
        },
        {
          component: StepQuestionaryNew,
          id: 3,
        },
      ];
    },
  },
  methods: {
    startQuestionary() {
      /*
        Registered users that didnt finish the signup questionary yet
        are redirect to here with forceQuestionary when try to access another page.
      */
      if (this.$route.params?.forceQuestionary) this.step = 3;
    },
  },
};
</script>
<style lang="scss">
iframe#launcher {
  visibility: initial;
}
</style>
